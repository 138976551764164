import { Session } from "@goodless/networking"
import { Category, Invoice, Order } from "@goodless/structures"
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding"

export class WebshopController {
    static shared: WebshopController = new WebshopController()

    cachedCategories: Category[] = []

    private constructor() {}

    async getOrder(id: string): Promise<Order> {
        const response = await Session.shared.authenticatedServer.request({
            method: "POST",
            path: "/customer/order/exchange",
            query: {
                order: id
            },
            decoder: Order as Decoder<Order>
        })
        return response.data
    }

    async getInvoicesFor(id: string): Promise<Invoice[]> {
        const response = await Session.shared.authenticatedServer.request({
            method: "GET",
            path: "/customer/order/"+id+"/invoices",
            decoder: new ArrayDecoder(Invoice as Decoder<Invoice>)
        })
        return response.data
    }
}