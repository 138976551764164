











import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar, NumberInput } from "@goodless/components"
import { Product } from "@goodless/structures";
import { GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton,
        NumberInput
    },
})
export default class ProductImageView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    product!: Product

    imageIndex = 0

    get imageSrc() {
        return this.product.images[this.imageIndex].getPathForSize(500, 500)
    }

    get canPrev() {
        return this.imageIndex > 0
    }

    get canNext() {
        return this.imageIndex < this.product.images.length - 1
    }

    nextImg() {
        if (this.imageIndex < this.product.images.length - 1) {
            this.imageIndex++
        }
    }

    prevImg() {
        if (this.imageIndex > 0) {
            this.imageIndex--
        }
    }
}
