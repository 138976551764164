










































import { FloatingFooter,InputBox, LoadingButton, ResetForgotPasswordView, Spinner } from "@goodless/components"
import { Session } from "@goodless/networking";
import { Category, Product } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { ArrayDecoder, Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import { WebshopController } from "../../classes/WebshopController";
import GeneralView from "../../components/GeneralView.vue";
import Menu from "../../components/Menu.vue";
import ProductContainerView from "../product/ProductContainerView.vue";
import LoginView from "./LoginView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        Spinner,
        Menu,
        GeneralView
    },
})
export default class WebshopView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false
    categories: Category[] = []

    // Make checkout reactive
    CheckoutController = CheckoutController.shared

    // Make session reactive
    Session = Session.shared

    lastUserId: string | null = "not-loaded-yet"

    get isLoggedIn() {
        return Session.shared.isComplete() && Session.shared.hasToken()
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    created() {
        this.changedToken();
        Session.shared.addListener(this, this.changedToken.bind(this));
    }

    destroyed() {
        Session.shared.removeListener(this);
    }

    changedToken() {
        if (this.isLoggedIn && Session.shared.user && Session.shared.user.id !== this.lastUserId) {
            this.lastUserId = Session.shared.user.id
            this.load().catch(console.error)
        } else {
            if (this.lastUserId !== null) {
                this.lastUserId = null
                this.load().catch(console.error)
            }
        }
    }

    login() {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(LoginView)
        }).setDisplayStyle("sheet"))
    }

    getAmountFor(productId: string) {
        return this.CheckoutController.getAmountFor(productId)
    }

    mounted() {
        console.log("Welcome view mounted")
        const params = new URL(window.location.href).searchParams;
        const parts = window.location.pathname.substring(1).split("/");
        HistoryManager.setUrl("/");

        if (parts.length == 1 && parts[0] == "reset-password") {
            console.log("Detected forgot password url")
            this.present(new ComponentWithProperties(ResetForgotPasswordView, {
                token: params.get("token")
            }).setDisplayStyle("popup").setAnimated(false))
        }
    }

    async load() {
        this.loading = true;
        try {
            const response = await Session.shared.optionalAuthenticatedServer.request({
                method: "GET",
                path: "/customer/categories",
                decoder: new ArrayDecoder(Category as Decoder<Category>)
            })
            this.categories = response.data
            WebshopController.shared.cachedCategories = response.data
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    openProduct(product: Product) {
        this.present(new ComponentWithProperties(ProductContainerView, {
            product
        }).setDisplayStyle("popup"))
    }

    getProductSrc(product: Product) {
        return product.images[0]?.getPathForSize(200, 200) ?? ""
    }
}
