














































import { BackButton,FloatingFooter, ForgotPasswordView, InputBox, LoadingButton, NavigationBar } from "@goodless/components"
import { NetworkManager, Session } from "@goodless/networking";
import { PasswordGrantStruct, PublicPriceList, Token } from "@goodless/structures";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import SignUpView from "./SignUpView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton
    },
})
export default class LoginView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    email = ""
    password = ""
    loading = false

    @Prop({ default: true })
    fullView: boolean

    @Prop({ default: null })
    priceList: PublicPriceList | null

    mounted() {
        if (this.fullView) {
            HistoryManager.setUrl("/login");
        }
    }

    async submit() {
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            const response = await NetworkManager.server.request({
                method: "POST",
                path: "/auth/token",
                body: new PasswordGrantStruct({
                    username: this.email,
                    password: this.password
                }),
                decoder: Token
            })
            await Session.shared.setToken(response.data)
            this.pop({ force: true })
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    forgot() {
        if (!this.canDismiss) {
            this.present(new ComponentWithProperties(ForgotPasswordView).setDisplayStyle("popup"))
        } else {
            this.show(new ComponentWithProperties(ForgotPasswordView))
        }
    }

    signUp() {
        if (!this.canDismiss) {
            this.present(new ComponentWithProperties(SignUpView).setDisplayStyle("popup"))
        } else {
            this.show(new ComponentWithProperties(SignUpView))
        }
    }
    
}
