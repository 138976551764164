import { CartItem, Checkout, Version } from "@goodless/structures";
import { Decoder, ObjectData, VersionBox, VersionBoxDecoder } from "@simonbackx/simple-encoding";

export class CheckoutController {
    checkout: Checkout

    static shared = new CheckoutController()

    private constructor() {
        this.checkout = Checkout.create({})

        // Try to load
        try {
            const item = localStorage.getItem("checkout")
            if (item) {
                const json = JSON.parse(item)
                const decoded = new VersionBoxDecoder(Checkout as Decoder<Checkout>).decode(new ObjectData(json, { version: 0 }))
                this.checkout = decoded.data
            }
        } catch (e) {
            console.error(e)
        }
    }

    clear() {
        this.checkout = Checkout.create({})
        this.save()
    }

    save() {
        // Try to load
        try {
            const json = new VersionBox(this.checkout).encode({ version: Version })
            localStorage.setItem("checkout", JSON.stringify(json))
        } catch (e) {
            console.error(e)
        }
    }

    addItem(item: CartItem) {
        this.checkout.cart.addItem(item)
        this.save()
    }

    removeItem(item, index) {
        this.checkout.cart.removeItem(item, index)
        this.save()
    }

    getAmountFor(productId: string) {
        return this.checkout.cart.items.reduce((c, i) => c + (i.product.id === productId ? i.amount : 0), 0)
    }
}