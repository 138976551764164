





































































































































import {
    AddressInput,
    BackButton,
    DateSelection,
    FloatingFooter,
    InputBox,
    LoadingButton,
    OptionInput,
    PhoneInput,
    Spinner,
    TimeInput,
} from "@goodless/components"
import { Session } from "@goodless/networking";
import {
    Address,
    Business,
    CheckoutCode,
    ContactPerson,
    Customer,
    CustomerSettings,
    OrderType
} from "@goodless/structures";
import {
    assertAfterFirstAvailableBusinessDay,
    Formatter,
    getFirstAvailableBusinessDay,
    isDateDisabled,
    isNullOrEmpty,
    isWeekend,
} from "@goodless/utilities";
import {Decoder} from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { isSimpleError, isSimpleErrors, SimpleError } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import Menu from "../../components/Menu.vue";
import PaymentSelectionView from "./PaymentSelectionView.vue";

@Component({
    components: {
        AddressInput,
        BackButton,
        DateSelection,
        FloatingFooter,
        GeneralErrorsView,
        InputBox,
        LoadingButton,
        Menu,
        OptionInput,
        PhoneInput,
        Spinner,
        TimeInput,
    },
})
export default class OrderDetailsView extends Mixins(NavigationMixin) {
    OrderType = OrderType
    errorBox: ErrorBox | null = null
    validator = new Validator()
    loading = false
    loadingNext = false

    @Prop({ default: null })
    initialCustomer!: Customer | null

    customer: Customer | null = this.initialCustomer
    rawCode = this.checkout.code?.code ?? ''

    created() {
        this.setDefaultDate()
        this.setDefaultContactPerson()
        this.setDefaultDeliveryAddress()
    }

    get firstAvailableDate() {
        return getFirstAvailableBusinessDay()
    }

    get maxDate() {
        let date: Date
        if (this.isDelivery) {
            if (this.deliveryDate === null) {
                return null
            }
            date = new Date(this.deliveryDate)
        } else {
            if (this.pickupDate === null) {
                return null
            }
            date = new Date(this.pickupDate)
        }
        let addedWeekdays = 4
        while(addedWeekdays > 0) {
            date.setDate(date.getDate() + 1)
            const day = date.getDay()

            if (day == 0 || day == 6) {
                //ignore weekend
            } else {
                addedWeekdays--
            }
        }
        date.setHours(16, 0, 0)
        return date
    }

    get checkout() {
        return CheckoutController.shared.checkout
    }

    get type() {
        return this.checkout.type
    }

    setType(type: OrderType) {
        if (this.checkout.type === type) {
            return
        }

        if (type === OrderType.PickUp) {
            this.checkout.calculatedPrices.deliveryCost = 0
        }

        this.checkout.type = type
        this.setDefaultDate()
        this.setDefaultContactPerson()
        this.setDefaultDeliveryAddress()
        this.errorBox = null

        CheckoutController.shared.save()
    }

    get isDelivery() {
        return this.checkout.type === OrderType.Delivery
    }

    get deliveryDate() {
        return this.checkout.deliveryDate
    }

    set deliveryDate(deliveryDate: Date | null) {
        if (deliveryDate && deliveryDate < this.firstAvailableDate) {
            deliveryDate = new Date(this.firstAvailableDate)
        }
        this.checkout.deliveryDate = deliveryDate
        CheckoutController.shared.save()
    }

    get retrievalDate() {
        return this.checkout.retrievalDate
    }

    set retrievalDate(retrievalDate: Date | null) {
        this.checkout.retrievalDate = retrievalDate
        CheckoutController.shared.save()
    }

    get pickupDate() {
        return this.checkout.pickupDate
    }

    set pickupDate(pickupDate: Date | null) {
        if (this.isDelivery && pickupDate !== null && pickupDate < this.firstAvailableDate) {
            pickupDate = new Date(this.firstAvailableDate)
        }
        this.checkout.pickupDate = pickupDate
        CheckoutController.shared.save()
    }

    get returnDate() {
        return this.checkout.returnDate
    }

    set returnDate(returnDate: Date | null) {
        this.checkout.returnDate = returnDate
        CheckoutController.shared.save()
    }


    get contactPersonName() {
        return this.checkout.contactPerson?.name ?? ""
    }

    set contactPersonName(name: string) {
        if (!this.checkout.contactPerson) {
            this.setDefaultContactPerson()
        }
        this.checkout.contactPerson!.name = name
        CheckoutController.shared.save()
    }

    get contactPersonPhone() {
        return this.checkout.contactPerson?.phone ?? ""
    }

    set contactPersonPhone(phone: string) {
        if (!this.checkout.contactPerson) {
            this.setDefaultContactPerson()
        }
        this.checkout.contactPerson!.phone = phone
        CheckoutController.shared.save()
    }

    get deliveryAddress() {
        return this.checkout.deliveryAddress
    }

    set deliveryAddress(deliveryAddress: Address | null) {
        if (this.checkout.deliveryAddress === null) {
            this.setDefaultDeliveryAddress()
        }
        this.checkout.deliveryAddress = deliveryAddress
        CheckoutController.shared.save()
    }

    get notes() {
        return this.checkout.notes
    }

    set notes(notes: string) {
        this.checkout.notes = notes
        CheckoutController.shared.save()
    }

    get code() {
        return this.rawCode
    }

    set code(code: string) {
        this.rawCode = code
    }

    setDefaultDate() {
        if (this.isDelivery) {
            this.deliveryDate = this.deliveryDate ?? this.firstAvailableDate
            this.retrievalDate = null
            this.pickupDate = null
        } else {
            this.pickupDate = this.pickupDate ?? this.firstAvailableDate
            this.returnDate = null
            this.deliveryDate = null
        }
    }

    setDefaultContactPerson() {
        if (this.isDelivery) {
            this.checkout.contactPerson = this.customer?.settings.contact ?? ContactPerson.create({})
        } else {
            this.checkout.contactPerson = ContactPerson.create({})
        }
    }

    setDefaultDeliveryAddress() {
        if (this.isDelivery) {
            this.checkout.deliveryAddress = this.customer?.settings.addresses[0] ?? Address.create({})
        } else {
            this.checkout.deliveryAddress = Address.create({})
        }
    }

    async validateAndSetCode() {
        if (isNullOrEmpty(this.rawCode)) {
            return false
        }
        try {
            const response = await Session.shared.optionalAuthenticatedServer.request({
                method: 'GET',
                path: `/customer/checkout-code/${this.rawCode}`,
                decoder: CheckoutCode as Decoder<CheckoutCode>,
            })
            this.checkout.code = response.data
            CheckoutController.shared.save()

            return true
        } catch (error) {
            this.checkout.code = null
            CheckoutController.shared.save()

            return false
        }
    }

    async submit() {
        if (this.loadingNext) {
            return
        }
        this.loadingNext = true

        try {
            if (!await this.validator.validate()) {
                this.loadingNext = false
                return
            }

            if (this.isDelivery) {
                if (this.deliveryDate === null) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul een leverdatum en tijdstip in",
                        field: "deliveryDate"
                    })
                }
                if (this.retrievalDate === null) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul een ophaaldatum en tijdstip in",
                        field: "retrievalDate"
                    })
                }
                if (this.retrievalDate > this.maxDate!) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: `Je kan maximaal voor 5 dagen huren, de ophaaldatum is dus ten laatste ${Formatter.dateTime(this.maxDate!)}`,
                        field: "retrievalDate"
                    })
                }
                assertAfterFirstAvailableBusinessDay(this.deliveryDate, 'Levering', 'deliveryDate')
                if (this.retrievalDate < this.deliveryDate) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "De ophaaldatum moet na de leverdatum liggen",
                        field: "retrievalDate"
                    })
                }
                if (this.deliveryAddress?.valid !== true) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul een (geldig) leveringsadres in",
                        field: "deliveryAddress"
                    })
                }
                if (this.contactPersonName.length < 2) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul de naam van de contactpersoon in",
                        field: "contactPerson.name"
                    })
                }
                if (this.contactPersonPhone.length < 2) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul het telefoonnummer van de contactpersoon in",
                        field: "contactPerson.phone"
                    })
                }
                if (!isNullOrEmpty(this.code) && !await this.validateAndSetCode()) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Deze leveringscode is niet geldig",
                        field: "code"
                    })
                }
                isDateDisabled(this.deliveryDate, 'deliveryDate')
                isDateDisabled(this.retrievalDate, 'retrievalDate')
                isWeekend(this.deliveryDate,'geleverd','deliveryDate')
                isWeekend(this.retrievalDate,'opgehaald' ,'retrievalDate' )
            } else {
                if (this.pickupDate === null) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul een ophaaldatum en tijdstip in",
                        field: "pickupDate"
                    })
                }
                if (this.returnDate === null) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "Vul een terugbrengdatum en tijdstip in",
                        field: "returnDate"
                    })
                }
                if (this.returnDate > this.maxDate!) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: `Je kan maximaal voor 5 dagen huren, de terugbrengdatum is dus ten laatste ${Formatter.dateTime(this.maxDate!)}`,
                        field: "returnDate"
                    })
                }
                assertAfterFirstAvailableBusinessDay(this.pickupDate,'Ophalen', 'pickupDate')
                if (this.returnDate < this.pickupDate) {
                    throw new SimpleError({
                        code: "invalid_field",
                        message: "De terugbrengdatum moet na de ophaaldatum liggen",
                        field: "returnDate"
                    })
                }
                isDateDisabled(this.pickupDate, 'pickupDate')
                isDateDisabled(this.returnDate, 'returnDate')
                isWeekend(this.pickupDate, 'opgehaald', 'pickupDate')
                isWeekend(this.returnDate,  'teruggebracht','returnDate',)
            }
            this.errorBox = null
            await this.saveCustomer()
            this.show(new ComponentWithProperties(PaymentSelectionView, { initialCustomer: this.customer }))
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingNext = false
    }

    async loadCustomer() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/customer",
                decoder: Customer as Decoder<Customer>
            })
            const customer = response.data
            this.customer = customer
            CheckoutController.shared.checkout.business = customer.settings.business ?? CheckoutController.shared.checkout.business ?? Business.create({})
            CheckoutController.shared.checkout.contactPerson = customer.settings.contact ?? CheckoutController.shared.checkout.contactPerson
            if (CheckoutController.shared.checkout.deliveryAddress === null) {
                CheckoutController.shared.checkout.deliveryAddress = customer.settings.addresses[0] ?? customer.settings.business?.address ?? Address.create({})
            }
            CheckoutController.shared.save()
        } catch (e) {
            if (isSimpleError(e) || isSimpleErrors(e)) {
                if (e.hasCode("no_customer")) {
                    // ignore
                    this.loading = false
                    return
                }
            }
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    async saveCustomer() {
        if (this.customer) {
            const response = await Session.shared.authenticatedServer.request({
                method: "PATCH",
                path: "/customer",
                decoder: Customer as Decoder<Customer>,
                body: Customer.patch({
                    settings: CustomerSettings.patch({
                        contact: this.checkout.contactPerson!
                    })
                })
            })
            this.customer = response.data
        } else {
            throw new Error("Something went wrong. Reload the page and try again.")
        }

    }

}
