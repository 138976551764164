

































































import { AddressInput, BackButton, CartFooter,FloatingFooter,InputBox, List, ListItem, LoadingButton, PhoneInput, Radio,Spinner  } from "@goodless/components"
import { Session } from "@goodless/networking";
import { OrderResponse, PaymentMethod } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import Menu from "../../components/Menu.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        Spinner,
        Menu,
        PhoneInput,
        BackButton,
        AddressInput,
        List,
        ListItem,
        Radio,
        CartFooter
    },
})
export default class PaymentSelectionView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    validator = new Validator()
    loadingNext = false
    CheckoutController = CheckoutController.shared

    mounted() {
        if (CheckoutController.shared.checkout.paymentMethod === null) {
            this.selectedMethod = PaymentMethod.Bancontact
        }
    }


    get PaymentMethod() {
        return PaymentMethod
    }

    get selectedMethod() {
        return CheckoutController.shared.checkout.paymentMethod ?? PaymentMethod.Bancontact
    }

    set selectedMethod(method: PaymentMethod) {
        CheckoutController.shared.checkout.paymentMethod = method
        CheckoutController.shared.save()
    }

    get kgSavedFormatted() {
        return ('' + this.kgSaved).replace(".", ",")
    }

    get kgSaved() {
        return CheckoutController.shared.checkout.cart.kgSaved
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    get checkout() {
        return CheckoutController.shared.checkout
    }

    get cart() {
        return CheckoutController.shared.checkout.cart
    }

    async submit() {
        if (this.loadingNext) {
            return
        }
        // todo
        this.loadingNext = true

        try {
            const response = await Session.shared.authenticatedServer!.request({
                method: "POST",
                path: "/customer/order",
                body: CheckoutController.shared.checkout,
                decoder: OrderResponse as Decoder<OrderResponse>
            })
            if (response.data.paymentUrl) {
                // Redirect, keep loading
                window.location.href = response.data.paymentUrl
                return
            }
            // Go to order view
            window.location.href = "/order/"+response.data.order.id
            return
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingNext = false
    }

}
