















import { ComponentWithProperties, NavigationController } from "@simonbackx/vue-app-navigation";
import { Component, Prop,Ref,Vue } from "vue-property-decorator";

@Component({
    components: {
        NavigationController
    },
})
export default class CheckoutNavigation extends Vue {
    step = 0;

    canPop = false

    @Prop({ default: null })
    totalSteps!: number | null

    @Prop({ required: true })
    root!: ComponentWithProperties

    @Ref("navigationController") readonly navigationController!: NavigationController;

    get progress() {
        return this.totalSteps && this.step ? ((this.step) / this.totalSteps) : 0
    }

    mounted() {
        // Trigger initial animation
        this.updateProgress()
    }

    updateProgress() {
        if (this.navigationController.components.length <= 1) {
            this.canPop = false
        }
        this.$nextTick(() => {
            this.canPop = (this.navigationController.mainComponent?.componentInstance() as any)?.isStepsPoppable ?? (this.navigationController.components.length > 1);
            this.step = (this.navigationController.mainComponent?.componentInstance() as any)?.step ?? -1;
            if (this.step == -1) {
               this.step = this.navigationController.components.length 
            }
        })
        
    }

    get canDismiss() {
        return (this.navigationController?.mainComponent?.componentInstance() as any)?.canDismiss ?? false
    }
}
