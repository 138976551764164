import { Version } from '@goodless/structures';
import { Request, RequestMiddleware, Server } from '@simonbackx/simple-networking';

export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class NetworkManagerStatic implements RequestMiddleware {
    displayedErrorMessage = false

    /**
     * Normal, non authenticated requests
     */
    get server() {
        // For some reasone we can't check if a process env variable is set because of the way webpack sets these variables (kinda replaces them)
        try {
            // Try API_URL
            const server = new Server(process.env.API_URL ? process.env.API_URL : (process.env.API_PORT ?  (window.location.protocol+"//"+window.location.hostname+":"+process.env.API_PORT) : ""))
            server.middlewares.push(this)
            return server
        } catch (e) {
            // env not set
        }
        
        // Try without API_URL (if not set)
        const server = new Server(process.env.API_PORT ?  (window.location.protocol+"//"+window.location.hostname+":"+process.env.API_PORT) : "")
        server.middlewares.push(this)
        return server
    }

    onBeforeRequest(request: Request<any>): Promise<void> {
        request.version = Version;
        (request as any).retryCount = ((request as any).retryCount ?? 0) + 1
        return Promise.resolve()
    }

    async shouldRetryNetworkError(request: Request<any>, error: Error): Promise<boolean> {
        console.error("network error", error)
        await sleep(Math.min(((request as any).retryCount ?? 0) * 1000, 7000));

        if ((request as any).retryCount > 1 && !this.displayedErrorMessage) {
            this.displayedErrorMessage = true;
            // todo: present error message
        }
        return Promise.resolve(true);
    }

    async shouldRetryServerError(request: Request<any>, response: XMLHttpRequest, error: Error): Promise<boolean> {
        console.error("server error", error)
        console.error(error)
        console.error(response)
        return Promise.resolve(false);
    }

    onNetworkResponse(request: Request<any>, response: XMLHttpRequest) {
        // todo: hide network error message
        this.displayedErrorMessage = false;
    }
}

export const NetworkManager = new NetworkManagerStatic()