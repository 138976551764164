import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: "nl", 
    fallbackLocale: 'nl',
    messages: {} 
});

const supportedLanguages = ["nl"]
let translations = {};

async function loadLanguage(lang: string): Promise<boolean> {
    // split locale into language
    lang = lang.substr(0, 2);

    if (!supportedLanguages.includes(lang)) {
        lang = "nl"
    }

    // language already loaded, skip importing
    if (Object.keys(translations).includes(lang)) return true;
    
    console.log("Downloading locale "+lang)

    // language not loaded yet, import from json and store in translations
    const loaded = await import(/* webpackChunkName: "lang-[request]" */ `@goodless/i18n/locales/${lang}.json`)
    if (loaded) {
        translations = { ...translations, [lang]: loaded.default }
        i18n.setLocaleMessage(lang, loaded.default)
        return true;
    }
    return false;
}


export async function switchLanguage(locale: string) {
    if (i18n.locale == locale) return;

    await loadLanguage(locale)
    i18n.locale = locale
}

// Load the current browser language
export async function load() {
    // Get the language from the OS
    let locale = window.navigator.languages ? window.navigator.languages[0] : null;
    locale = locale || window.navigator.language || (window.navigator as any).browserLanguage || (window.navigator as any).userLanguage;

    if (!locale) {
        // Default to Dutch
        locale = "nl"
    }


    const lang = locale.substr(0, 2);

    if (!supportedLanguages.includes(lang)) {
        // Fallback to default
        locale = "nl"
    }
    i18n.locale = locale

    await loadLanguage(locale);
}