



















































import { BackButton,FloatingFooter, InputBox, LoadingButton, NavigationBar, NumberInput } from "@goodless/components"
import { Session } from "@goodless/networking";
import { CartItem, Product } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { HistoryManager, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton,
        NumberInput
    },
})
export default class ProductView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null

    @Prop({ required: true })
    product!: Product

    amount = this.product.soldPer

    get stepValue() {
        return this.product.soldPer
    }

    get isLoggedIn() {
        return Session.shared.isComplete() && Session.shared.hasToken()
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    mounted() {
        HistoryManager.setUrl("/product/"+this.product.id);
    }

    addToCart() {
        CheckoutController.shared.addItem(CartItem.create({
            product: this.product,
            amount: this.amount
        }))
        this.dismiss({ force: true })
    }

    get boxes() {
        return this.amount / this.product.soldPer
    }

    get imageSrc() {
        return this.product.images[0].getPathForSize(500, 500)
    }
}
