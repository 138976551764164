


















































import {
    AddressInput,
    EmailInput,
    FloatingFooter,
    InputBox,
    LoadingButton,
    PhoneInput,
    Spinner,
    VATInput,
} from "@goodless/components"
import { Session } from "@goodless/networking";
import { Address, Business, CreateCustomer, Customer, CustomerSettings } from "@goodless/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { ErrorBox, GeneralErrorsView, Validator } from "@simonbackx/simple-error-forms";
import { isSimpleError, isSimpleErrors, SimpleError } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import Menu from "../../components/Menu.vue";
import OrderDetailsView from "./OrderDetailsView.vue";

@Component({
    components: {
        AddressInput,
        EmailInput,
        FloatingFooter,
        GeneralErrorsView,
        InputBox,
        LoadingButton,
        Menu,
        PhoneInput,
        Spinner,
        VATInput,
    },
})
export default class InvoiceView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    validator = new Validator()
    loading = true
    loadingNext = false

    customer: Customer | null = null

    CheckoutController = CheckoutController.shared

    get business() {
        return CheckoutController.shared.checkout.business
    }

    mounted() {
        this.load().catch(console.error)
    }

    async load() {
        await this.loadCustomer()
    }

    get name() {
        return this.business?.name ?? ""
    }

    set name(name: string) {
        if (!this.business) {
            CheckoutController.shared.checkout.business = this.customer?.settings.business ?? Business.create({})
        }
        this.business!.name = name
        CheckoutController.shared.save()
    }

    get VATNumber() {
        return this.business?.VATNumber ?? ""
    }

    set VATNumber(VATNumber: string) {
        if (!this.business) {
            CheckoutController.shared.checkout.business = this.customer?.settings.business ?? Business.create({})
        }
        this.business!.VATNumber = VATNumber
        CheckoutController.shared.save()
    }

    get phone() {
        return this.business?.phone ?? ""
    }

    set phone(phone: string) {
        if (!this.business) {
            CheckoutController.shared.checkout.business = this.customer?.settings.business ?? Business.create({})
        }
        this.business!.phone = phone
        CheckoutController.shared.save()
    }

    get email() {
        return this.business?.email ?? ""
    }

    set email(email: string) {
        if (!this.business) {
            CheckoutController.shared.checkout.business = this.customer?.settings.business ?? Business.create({})
        }
        this.business!.email = email
        CheckoutController.shared.save()
    }

    get address() {
        return this.business?.address ?? Address.create({})
    }

    set address(address: Address) {
        if (!this.business) {
            CheckoutController.shared.checkout.business = this.customer?.settings.business ?? Business.create({})
        }
        this.business!.address = address
        CheckoutController.shared.save()
    }

    async submit() {
        if (this.loadingNext) {
            return
        }
        // todo
        this.loadingNext = true

        try {
            if (!await this.validator.validate()) {
                this.loadingNext = false
                return
            }

            if (this.name.length < 2) {
                throw new SimpleError({
                    code: "invalid_field",
                    message: "Vul de naam van jouw bedrijf / vereniging in",
                    field: "name"
                })
            }
            this.errorBox = null
            await this.saveCustomer()
            this.show(new ComponentWithProperties(OrderDetailsView, { initialCustomer: this.customer }))
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loadingNext = false
    }

    async saveCustomer() {
        if (this.customer) {
            const response = await Session.shared.authenticatedServer.request({
                method: "PATCH",
                path: "/customer",
                decoder: Customer as Decoder<Customer>,
                body: Customer.patch({ settings: CustomerSettings.patch({ business: this.business! }) })
            })
            this.customer = response.data
        } else {
            const response = await Session.shared.authenticatedServer.request({
                method: "POST",
                path: "/customer",
                decoder: Customer as Decoder<Customer>,
                body: CreateCustomer.create({ settings: CustomerSettings.create({ business: this.business! }) })
            })
            this.customer = response.data
        }

    }

    async loadCustomer() {
        this.loading = true

        try {
            const response = await Session.shared.authenticatedServer.request({
                method: "GET",
                path: "/customer",
                decoder: Customer as Decoder<Customer>
            })
            const customer = response.data
            this.customer = customer

            CheckoutController.shared.checkout.business = customer.settings.business ?? CheckoutController.shared.checkout.business ?? Business.create({})
            CheckoutController.shared.checkout.contactPerson = customer.settings.contact ?? CheckoutController.shared.checkout.contactPerson

            if (CheckoutController.shared.checkout.deliveryAddress === null) {
                CheckoutController.shared.checkout.deliveryAddress = customer.settings.addresses[0] ?? customer.settings.business?.address ?? Address.create({})
            }
            CheckoutController.shared.save()
        } catch (e) {
            if (isSimpleError(e) || isSimpleErrors(e)) {
                if (e.hasCode("no_customer")) {
                    // ignore

                    if (CheckoutController.shared.checkout.business === null) {
                        CheckoutController.shared.checkout.business = Business.create({})
                    }
                    this.loading = false
                    return
                }
            }
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

}
