

















import { NavigationBar } from "@goodless/components"
import { Product } from "@goodless/structures";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import ProductImageView from "./ProductImageView.vue"
import ProductView from "./ProductView.vue"

@Component({
    components: {
        ProductImageView,
        ProductView,
        NavigationBar
    },
})
export default class ProductContainerView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    product!: Product
}
