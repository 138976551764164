






































import { FloatingFooter,InputBox, LoadingButton, Spinner } from "@goodless/components"
import { GlobalEventBus, Session } from "@goodless/networking";
import { GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutController } from "../classes/CheckoutController";
import AccountView from "../views/account/AccountView.vue";
import CartView from "../views/cart/CartView.vue";
import CheckoutNavigation from "../views/checkout/CheckoutNavigation.vue";
import InvoiceView from "../views/checkout/InvoiceView.vue";
import LoginView from "../views/welcome/LoginView.vue";
import SignUpView from "../views/welcome/SignUpView.vue";
import Menu from "./Menu.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        Spinner,
        Menu
    },
})
export default class GeneralView extends Mixins(NavigationMixin) {
    // Make checkout reactive
    CheckoutControllder = CheckoutController.shared

    // Make session reactive
    Session = Session.shared

    get isLoggedIn() {
        return Session.shared.isComplete() && Session.shared.hasToken()
    }

    mounted() {
        GlobalEventBus.addListener(this, "checkout", async () => {
            console.log("Going to checkout")
            this.present(new ComponentWithProperties(CheckoutNavigation, {
                root: new ComponentWithProperties(InvoiceView),
                totalSteps: 3
            }))
        })
    }

    destroyed() {
        GlobalEventBus.removeListener(this)
    }


    createAccount() {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(SignUpView)
        }).setDisplayStyle("sheet"))
    }

    login() {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(LoginView)
        }).setDisplayStyle("sheet"))
    }

    openCart() {
        this.present(new ComponentWithProperties(NavigationController, {
            root: new ComponentWithProperties(CartView)
        }).setDisplayStyle("popup"))
    }
    
    openAccount() {
        this.present(new ComponentWithProperties(AccountView).setDisplayStyle("popup"))
    }
}
