







import { PublicPriceList } from "@goodless/structures";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import GeneralView from "../../components/GeneralView.vue";
import LoginView from "./LoginView.vue"

@Component({
    components: {
        GeneralView,
        LoginView
    },
})
export default class LoginContainer extends Mixins(NavigationMixin) {
    @Prop({ default: null })
    priceList: PublicPriceList | null
}
