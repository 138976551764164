
























import { CartFooter, CartItems, FloatingFooter, List, ListItem, LoadingButton, OrderDetails,Spinner } from "@goodless/components"
import { Session } from "@goodless/networking";
import { Order, PaymentStatus, PublicPriceList } from "@goodless/structures";
import { ErrorBox } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import { WebshopController } from "../../classes/WebshopController";
import GeneralView from "../../components/GeneralView.vue";

@Component({
    components: {
        LoadingButton,
        FloatingFooter,
        Spinner,
        GeneralView,
        CartItems,
        CartFooter,
        List,
        ListItem,
        OrderDetails
    },
})
export default class JoinPriceListView extends Mixins(NavigationMixin) {
    loading = true

    @Prop({ required: true })
    priceList: PublicPriceList

    errorBox: ErrorBox | null

  
    mounted() {
        this.join().catch(console.error)
    }

    async join() {
        try {
            await Session.shared.authenticatedServer.request({
                method: "POST",
                path: "/customer/price-lists/"+this.priceList.id+"/join"
            })
            this.loading = false
        } catch (e) {
            console.error(e)
        }
    }
}
