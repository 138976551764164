




















































import { CartFooter, CartItems, FloatingFooter, List, ListItem, LoadingButton, OrderDetails,Spinner } from "@goodless/components"
import {Invoice, Order, PaymentMethod, PaymentStatus} from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import { WebshopController } from "../../classes/WebshopController";
import GeneralView from "../../components/GeneralView.vue";

@Component({
    components: {
        LoadingButton,
        FloatingFooter,
        Spinner,
        GeneralView,
        CartItems,
        CartFooter,
        List,
        ListItem,
        OrderDetails
    },
})
export default class OrderView extends Mixins(NavigationMixin) {
    @Prop({ required: true })
    order: Order

    @Prop({ default: false })
    isNew: boolean

    invoices: Invoice[] = []
    loadingInvoices = true

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    mounted() {
        this.setTimer()
        this.updateCart()
    }

    updateCart() {
        if (
            (this.isNew && this.order.paymentStatus == PaymentStatus.Succeeded)
            || this.order.data.paymentMethod === PaymentMethod.Transfer
        ) {
            CheckoutController.shared.clear()

            // todo: remove 'new' from url
        }
    }

    setTimer() {
        if (!this.order.number) {
            window.setTimeout(() => {
                this.checkOrder().catch(console.error)
            }, 5000)
        } else {
            // Download invoices
            this.downloadInvoices().catch(console.error)
        }
    }

    async checkOrder() {
        try {
            const updated = await WebshopController.shared.getOrder(this.order.id)
            this.order.set(updated)
            this.updateCart()
        } catch (e) {
            console.error(e)
        }
        this.setTimer()
    }

    async downloadInvoices() {
        try {
            this.loadingInvoices = true
            this.invoices = await WebshopController.shared.getInvoicesFor(this.order.id)
        } catch (e) {
            console.error(e)
        }
        this.loadingInvoices = false
    }
}
