import { Token, User, Version } from '@goodless/structures'
import { Decoder, ObjectData } from '@simonbackx/simple-encoding'
import { SimpleErrors } from '@simonbackx/simple-errors'
import { Request, RequestMiddleware } from '@simonbackx/simple-networking'

import { ManagedToken } from './ManagedToken'
import { NetworkManager } from './NetworkManager'

type AuthenticationStateListener = () => void

export class Session implements RequestMiddleware {
    // Singleton
    static shared = new Session(true)

    user: User | null = null

    isPublic = false

    protected token: ManagedToken | null = null
    protected listeners = new Map<any, AuthenticationStateListener>()

    constructor(isPublic = false) {
        this.isPublic = isPublic

        if (isPublic) {
            this.loadFromStorage().catch(e => {
                console.error(e)
            })
        }
    }

    copyFrom(session: Session) {
        this.user = session.user
        this.token = session.token
        this.saveToStorage()
        this.callListeners()
    }

    async loadFromStorage() {
        // Check localstorage
        const json = localStorage.getItem('token')
        if (json) {
            try {
                const parsed = JSON.parse(json)
                const token = Token.decode(new ObjectData(parsed, { version: Version }))
                await this.setToken(token)
                console.log('Successfully loaded token from storage')
            } catch (e) {
                console.error(e)
            }
        }
    }

    saveToStorage() {
        if (!this.isPublic) {
            return
        }

        // Save token to localStorage
        if (this.token) {
            localStorage.setItem('token', JSON.stringify(this.token.token.encode({ version: Version })))
        } else {
            localStorage.removeItem('token')
        }
        console.log('Saved token to storage')
    }

    addListener(owner: any, listener: AuthenticationStateListener) {
        this.listeners.set(owner, listener)
    }

    removeListener(owner: any) {
        this.listeners.delete(owner)
    }

    protected callListeners() {
        for (const listener of this.listeners.values()) {
            listener()
        }
    }

    hasToken(): boolean {
        return !!this.token
    }

    canGetCompleted(): boolean {
        return !!this.token
    }

    isComplete(): boolean {
        return !!this.token && !!this.user
    }

    /**
     * Doing unauthenticated requests
     */
    get server() {
        return NetworkManager.server
    }

    /**
     * Doing authenticated requests
     */
    get optionalAuthenticatedServer() {
        if (!this.hasToken()) {
            return this.server
        }
        const server = this.server
        server.middlewares.push(this)
        return server
    }

    /**
     * Doing authenticated requests
     */
    get authenticatedServer() {
        if (!this.hasToken()) {
            throw new Error("Could not get authenticated server without token")
        }
        const server = this.server
        server.middlewares.push(this)
        return server
    }

    protected onTokenChanged() {
        this.saveToStorage()
        this.callListeners()
    }

    async setToken(token: Token) {
        if (this.token) {
            // Disable listener before clearing the token
            this.token.onChange = () => {
                // emtpy
            }
        }
        this.token = new ManagedToken(token, () => {
            this.onTokenChanged()
        });
        await this.completeData()
        this.saveToStorage()
    }


    async fetchUser(): Promise<User> {
        const response = await this.authenticatedServer.request({
            method: "GET",
            path: "/auth/user",
            decoder: User as Decoder<User>
        })
        this.user = response.data
        this.callListeners()
        return response.data
    }

    async completeData() {
        try {
            await this.fetchUser()
        } catch (e) {
            this.temporaryLogout()
            throw e;
        }
    }

    // Logout without clearing this token
    temporaryLogout() {
        // We do not call ontoken changed -> prevent saving!!!!
        // Might still be able to login after a reload (because the error was caused by data errors)
        if (this.token) {
            this.token.onChange = () => {
                // emtpy
            }
            this.token = null;
            this.callListeners()
        }
    }

    logout() {
        if (this.token) {
            this.token.onChange = () => {
                // emtpy
            }
            this.token = null;
            this.user = null;

            // Call listeners + save to storage
            this.onTokenChanged();
        }
    }

    // -- Implementation for requestMiddleware ----

    async onBeforeRequest(request: Request<any>): Promise<void> {
        if (!this.token) {
            // Euhm? The user is not signed in!
            throw new Error("Could not authenticate request without token")
        }

        if (this.token.isRefreshing() || this.token.needsRefresh()) {
            // Already expired.
            console.log("Request started with expired access token, refreshing before starting request...")
            await this.token.refresh(this.server)
        }

        request.headers["Authorization"] = "Bearer " + this.token.token.accessToken;
    }

    async shouldRetryError(request: Request<any>, response: XMLHttpRequest, error: SimpleErrors): Promise<boolean> {
        if (!this.token) {
            // The user is not signed in! (probably logged out between the start of the request and now)
            return false;
        }

        if (response.status != 401) {
            return false;
        }

        if (error.hasCode("expired_access_token")) {
            if (request.headers.Authorization != "Bearer " + this.token.token.accessToken) {
                console.log("This request started with an old token that might not be valid anymore. Retry with new token before doing a refresh")
                return true
            }

            if (!request.headers.Authorization) {
                console.error("Request started without authorization header!")
                return false
            }

            // Try to refresh
            try {
                console.log("Request failed due to expired access token, refreshing...")
                await this.token.refresh(this.server)
                console.log("Retrying request...")
            } catch (e) {
                this.logout();
                return false;
            }
            return true
        } else {
            if (request.headers.Authorization != "Bearer " + this.token.token.accessToken) {
                console.log("This request started with an old token that might not be valid anymore. Retry with new token")
                return true
            } else {
                console.log(`logout caused by access token ${request.headers.Authorization}`)
                this.logout();
            }
        }

        return false
    }
}
