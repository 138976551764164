






import { AuthenticatedView, PromiseView } from "@goodless/containers";
import { load } from "@goodless/i18n";
import { Session } from "@goodless/networking";
import { PublicPriceList } from "@goodless/structures";
import { Decoder } from "@simonbackx/simple-encoding";
import { ComponentWithProperties, HistoryManager, ModalStackComponent, NavigationController } from "@simonbackx/vue-app-navigation";
import { Component, Vue } from "vue-property-decorator";

import { WebshopController } from "./classes/WebshopController"
import OrderView from "./views/orders/OrderView.vue";
import JoinPriceListView from "./views/price-lists/JoinPriceListView.vue";
import LoginContainer from "./views/welcome/LoginContainer.vue";
import WebshopView from "./views/welcome/WebshopView.vue";

@Component({
    components: {
        ModalStackComponent
    },
})
export default class App extends Vue {
    root = this.getComponent()

    getComponent() {
        return new ComponentWithProperties(PromiseView, {
            promise: this.buildComponent.bind(this)
        })
    }

    async buildComponent() {
        const params = new URL(window.location.href).searchParams;
        const parts = window.location.pathname.substring(1).split("/");

        // Load languages first
        await load()

        try {
            if (parts.length == 2 && parts[0] == "order") {
                const isNew = params.has("new")

                // Go to order view

                return new ComponentWithProperties(AuthenticatedView, {
                    root: new ComponentWithProperties(PromiseView, {
                        promise: async () => {
                            const order = await WebshopController.shared.getOrder(parts[1])
                            return new ComponentWithProperties(NavigationController, {
                                root: new ComponentWithProperties(OrderView, {
                                    order,
                                    isNew
                                })
                            })
                        }
                    }),
                    loginRoot: new ComponentWithProperties(LoginContainer)
                });
            }

            if (parts.length == 2 && parts[0] == "join") {
                // Go to order view
                const response = await Session.shared.optionalAuthenticatedServer.request({
                    method: "GET",
                    path: "/customer/price-lists",
                    query: {
                        url: parts[1]
                    },
                    decoder: PublicPriceList as Decoder<PublicPriceList>
                })
                const priceList = response.data

                return new ComponentWithProperties(AuthenticatedView, {
                    root: new ComponentWithProperties(NavigationController, {
                        root: new ComponentWithProperties(JoinPriceListView, {
                            priceList
                        })
                    }),
                    loginRoot: new ComponentWithProperties(LoginContainer, {
                        priceList
                    })
                });
            }
            return new ComponentWithProperties(NavigationController, {
                root: new ComponentWithProperties(WebshopView, {})
            });
        } catch (e) {
            console.error(e)
            return new ComponentWithProperties(NavigationController, {
                root: new ComponentWithProperties(WebshopView, {})
            });
        }
    }

    mounted() {
        HistoryManager.activate();
    }
}
