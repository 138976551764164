




































import { BackButton,FloatingFooter, InputBox, List, ListItem, LoadingButton, NavigationBar, CartItems, CartFooter  } from "@goodless/components"
import { GlobalEventBus, Session } from "@goodless/networking";
import { Product } from "@goodless/structures";
import { Formatter } from "@goodless/utilities";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { ComponentWithProperties, HistoryManager, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutController } from "../../classes/CheckoutController";
import { WebshopController } from "../../classes/WebshopController";
import LoginView from "../welcome/LoginView.vue";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton,
        List,
        ListItem,
        CartItems,
        CartFooter
    },
})
export default class CartView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    // Keep reactive
    CheckoutController = CheckoutController.shared

    get checkout() {
        return CheckoutController.shared.checkout
    }

    get cart() {
        return CheckoutController.shared.checkout.cart
    }

    updateCart() {
        this.CheckoutController.save()
    }

    get isLoggedIn() {
        return Session.shared.isComplete() && Session.shared.hasToken()
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    getProductSrc(product: Product) {
        return product.images[0]?.getPathForSize(200, 200) ?? ""
    }

    mounted() {
        HistoryManager.setUrl("/cart");
        this.cart.validate(WebshopController.shared.cachedCategories.flatMap(p => p.products))
    }

    async submit() {
        if (!this.isLoggedIn) {
            this.present(new ComponentWithProperties(NavigationController, {
                root: new ComponentWithProperties(LoginView)
            }).setDisplayStyle("sheet"))
            return
        }
        if (this.loading) {
            return
        }
        this.loading = true
        this.errorBox = null

        try {
            this.dismiss({ force: true })
            await GlobalEventBus.sendEvent("checkout", this)
        } catch (e) {
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

}
