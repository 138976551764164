































import { BackButton,FloatingFooter, InputBox, List, ListItem, LoadingButton, NavigationBar, StepperInput } from "@goodless/components"
import { Session } from "@goodless/networking";
import { ErrorBox, GeneralErrorsView } from "@simonbackx/simple-error-forms";
import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Component, Mixins } from "vue-property-decorator";

@Component({
    components: {
        InputBox,
        GeneralErrorsView,
        LoadingButton,
        FloatingFooter,
        NavigationBar,
        BackButton,
        List,
        ListItem,
        StepperInput
    },
})
export default class AccountView extends Mixins(NavigationMixin) {
    errorBox: ErrorBox | null = null
    loading = false

    logout() {
        Session.shared.logout()
        this.pop({ force: true })
    }

    get firstName() {
        return Session.shared.user?.firstName ?? ""
    }
    
}
